<template>
  <div class="order-management">
    <div class="top-integral">
      图片
      <div class="fraction">积分：{{ integral }}</div>
    </div>
    <template v-for="item in list">
      <div
        class="center-integral"
        :key="item.id"
        v-if="item.goodsTypeCode === '1101'"
        @click="toPage(item)"
      >
        <div class="part left-part">
          <div class="cheep">{{ item.goodsTypeName }}</div>
        </div>
        <div class="part center-part">
          <p style="color: #d1b876">
            {{ item.goodsName }}
          </p>
          <p>{{ item.goodsDesc }}</p>
          <p style="color: #ea9772">共{{ item.goodsNum }}份</p>
        </div>
        <div class="part right-part">
          <p style="color: #ea9772">{{ item.integralNum }}积分</p>
          <p>剩余{{ item.lastGoodsNum }}份</p>
          <button class="part-button">立即兑换</button>
        </div>
      </div>
      <div
        class="center-integral"
        :key="item.id"
        v-if="item.goodsTypeCode === '1102'"
        @click="toPage(item)"
      >
        <div class="part left-part">
          <div class="cheep nother-cheep">{{ item.goodsTypeName }}</div>
        </div>
        <div class="part center-part">
          <p style="color: #d1b876">
            {{ item.goodsName }}
          </p>
          <p>{{ item.goodsDesc }}</p>
          <p style="color: #ea9772">共{{ item.goodsNum }}份</p>
        </div>
        <div class="part right-part">
          <p style="color: #ea9772">{{ item.integralNum }}积分</p>
          <p>剩余{{ item.lastGoodsNum }}份</p>
          <button class="part-button">立即兑换</button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { jifen } from '../../utils/apis/apis'
export default {
  name: 'login',
  data() {
    return {
      integral: 0,
      pageInfo: {
        pageSize: 50,
        pageNum: 1,
        total: 0
      },
      list: []
    }
  },

  created() {
    this.getData()
  },
  methods: {
    toPage(item) {
      this.$router.push('/discount/' + item.id)
    },
    getData() {
      this.$reqPost(jifen.list, this.pageInfo).then((res) => {
        if (res.data.code === 200) {
          this.list = [...this.list, ...res.data.data.pageInfo.list]
          // console.log(this.list)
          this.pageInfo.total = res.data.data.total
          this.integral = res.data.data.integral
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
</style>
